<template>
	<transition name="loading-spinner" appear>
		<div class="loading-spinner">
			<svg
				class="loading-spinner__draw"
				preserveAspectRatio="xMidYMid meet"
				focusable="false"
				:viewBox="`0 0 ${diameter} ${diameter}`"
				ref="loading-spinner__draw">
				<circle class="loading-spinner__circle" cx="50%" cy="50%" :r="circleRadius" ref="loading-spinner__circle"></circle>
			</svg>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'loadingSpinner',
	props: {
		diameter: {
			type: Number,
			default: 40,
		},
		stroke: {
			type: Number,
			default: 4,
		},
	},
	computed: {
		circleRadius() {
			return (this.diameter - this.stroke) / 2
		},
		circleStrokeWidth() {
			return `${this.stroke}px`
		},
		circleCircumference() {
			return 2 * Math.PI * this.circleRadius
		},
		circleStrokeDashArray () {
			return this.circleCircumference + 'px'
		},
		circleStrokeDashOffset () {
			// if (this.isDeterminate) {
			// 	return this.circleCircumference * (100 - this.mdValue) / 100 + 'px'
			// }
			// if (this.isIndeterminate && this.isIE) {
			// 	}
			return this.circleCircumference * 0.2 + 'px'
			// return null
		}
	},
	mounted() {
		this.attachSvgStyle()
		this.attachCircleStyle()
	},
	methods: {
		attachSvgStyle() {
			const svg = this.$refs['loading-spinner__draw']
			const size = `${this.diameter}px`
			svg.style.width = size
			svg.style.height = size
		},
		attachCircleStyle() {
			const circle = this.$refs['loading-spinner__circle']
			// circle.style.strokeDashoffset = this.circleStrokeDashOffset
			circle.style.strokeDasharray = this.circleStrokeDashArray
			circle.style.strokeWidth = this.circleStrokeWidth
			circle.style.setProperty('--md-progress-spinner-start-value', 0.95 * this.circleCircumference)
			circle.style.setProperty('--md-progress-spinner-end-value', 0.2 * this.circleCircumference)
		},
	},
}
</script>

<style scoped lang="scss">
.loading-spinner {
	display: inline-flex;
	position: relative;
	animation: md-progress-spinner-rotate 2s linear infinite;

	&.loading-spinner-enter,
      &.loading-spinner-leave-to {
        .loading-spinner-draw {
          opacity: 0;
          transform: scale(.1);
        }
	  }

	  &.loading-spinner-enter-active,
      &.loading-spinner-leave-active {
        transition-duration: .4s;
        animation: none;
      }

	&__draw {
		color: modules.color_('primary');
		overflow: visible;
		transform: scale(1) rotate(-90deg);
		transform-origin: center;
		transition: .4s modules.$ease-standard;
		will-change: opacity, transform;
	}

	&__circle {
		color: modules.color_('primary');
		stroke: modules.color_('primary');
		fill: none;
		transform-origin: center;
		transition: stroke-dashoffset .25s modules.$ease-standard;

		animation: 4s infinite modules.$ease-standard;
        animation-name: md-progress-spinner-stroke-rotate;

	}
}

@keyframes md-progress-spinner-rotate {
    0% {
      transform: rotate(0)
    }
    100% {
      transform: rotate(360deg)
    }
  }
  @keyframes md-progress-spinner-initial-rotate {
    0% {
      opacity: 0;
      transform: rotate(-90deg) translateZ(0);
    }
    20% {
      opacity: 1;
    }
    100% {
      transform: rotate(270deg) translateZ(0);
    }
  }
  @keyframes md-progress-spinner-stroke-rotate-fallback {
    0% {
      transform: rotate(0)
    }
    25% {
      transform: rotate(1170deg)
    }
    50% {
      transform: rotate(2340deg)
    }
    75% {
      transform: rotate(3510deg)
    }
    100% {
      transform: rotate(4680deg)
    }
  }
  @keyframes md-progress-spinner-stroke-rotate {
    0% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotate(0);
    }
    12.5% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotate(0);
    }
    12.51% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(72.5deg);
    }
    25% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(72.5deg);
    }
    25.1% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotate(270deg);
    }
    37.5% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotate(270deg);
    }
    37.51% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(161.5deg);
    }
    50% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(161.5deg);
    }
    50.01% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotate(180deg);
    }
    62.5% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotate(180deg);
    }
    62.51% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(251.5deg);
    }
    75% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(251.5deg);
    }
    75.01% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotate(90deg);
    }
    87.5% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotate(90deg);
    }
    87.51% {
      stroke-dashoffset: var(--md-progress-spinner-end-value);
      transform: rotateX(180deg) rotate(341.5deg);
    }
    100% {
      stroke-dashoffset: var(--md-progress-spinner-start-value);
      transform: rotateX(180deg) rotate(341.5deg);
    }
  }
</style>
