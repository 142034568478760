<template>
	<label class="mdc-text-field mdc-text-field--filled" :for="uid" :class="{'mdc-text-field--label-floating': attrs.value, 'mdc-text-field--disabled': disabled == true, 'mdc-text-field--compact': compact}">
		<span class="mdc-text-field__ripple" v-if="ripple"></span>
		<span class="mdc-text-field__affix mdc-text-field__affix--prefix"><slot name="pre"></slot></span>
		<input class="mdc-text-field__input" :type="type" :name="name" :id="uid" :autocomplete="autocomplete" :placeholder="placeholder" :aria-label="label" :required="required" :aria-required="required" v-bind="attrs" v-on="listeners" @input="input" :aria-disabled="disabled" :disabled="disabled" ref="field">
		<span class="mdc-text-field__affix mdc-text-field__affix--suffix"><slot name="post"></slot></span>
		<span class="mdc-floating-label" :class="{'mdc-floating-label--float-above': attrs.value}" :id="`${uid}_label`" v-if="label" v-html="label"></span>
		<span class="mdc-line-ripple" v-if="ripple"></span>
	</label>
</template>

<script>
import { v4 } from 'uuid'
import { MDCTextField } from '@material/textfield'

export default {
	name: 'textField',
	data: () => ({
		textfield: null,
	}),
	props: {
		autocomplete: String,
		compact: false,
		disabled: {
			type: Boolean,
			default: false,
		},
		id: String,
		label: String,
		name: String,
		type: String,
		placeholder: String,
		ripple: {
			type: Boolean,
			default: false,
		},
		minlength: {
			type: [Number, String],
			default: null,
		},
		maxlength: {
			type: [Number, String],
			default: null,
		},
		small: Boolean,
		required: false,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event)
			}
		},
		hasPre() {
			return Boolean(this.$slots.pre)
		},
		hasPost() {
			return Boolean(this.$slots.post)
		},
		uid() {
			if (!this.id) {
				return v4()
			}
		},
	},
	mounted() {
		this.textfield = new MDCTextField(this.$el)
		if (this.$attrs.novalidate) {
			this.textfield.useNativeValidation = true
		}

		if (this.$refs.field.min) {
			this.$refs.field.addEventListener('blur', () => {
				const amt = this.$refs.field.value.replace(/[^0-9]+/g, '')
				if (Number(amt) < this.$refs.field.min) {
					this.$refs.field.value = this.$refs.field.min
					this.$refs.field.dispatchEvent(new Event('input'))
				}
			})
		}

		if (this.$refs.field.max) {
			this.$refs.field.addEventListener('blur', () => {
				const amt = this.$refs.field.value.replace(/[^0-9]+/g, '')
				if (Number(amt) > this.$refs.field.max) {
					this.$refs.field.value = this.$refs.field.max
					this.$refs.field.dispatchEvent(new Event('input'))
				}
			})
		}

	},
	methods: {
		input(evt) {
			this.$emit('input', evt.target.value)
		}
	},
}
</script>

<style scoped lang="scss">
.mdc-text-field {
	&--compact {
		height: 38px;
		border-radius: 100px;

		.mdc-text-field__input {
			font-size: 14px;
		}

		&.mdc-text-field--filled {
			&:before {
				height: 28px;
			}
		}

		&.mdc-text-field--label-floating {
			.mdc-floating-label {
				display: none;
			}
		}

		.mdc-floating-label {
			@include modules.fontSize(14px);
		}

		&.form__field--search {

			@include modules.media-query('phone') {
				width: 100%;
			}

			.mdc-text-field__affix {
				opacity: 1;
			}

			.mdc-floating-label {
				padding-left: 20px;
			}

			.mdc-text-field__affix--prefix {
				top: -2px;
				position: relative;
			}

			.mdc-text-field__input {
				position: relative;
				top: -6px;
				left: 4px;
			}
		}
	}
}
</style>
